import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Pay to Cash</span>
                            <h3>About this Service</h3>
                            <p>Whether a company is manufacturing a product, providing a service, or at the retail side, they need to procure certain raw materials. This process connects the procurement and finance functions. The major challenge of any company is to find an ideal service provider, negotiate contracts, minimize spending, and identify scopes to automate the purchasing cycle.</p>
                            <p>The Pay process starts with the requisitioning of goods and services and ends with processing payments to suppliers. But a fully integrated P2C system is more than that. P2C is an efficient and effective solution that incorporates touchless automation to reduce errors and improve your company's bottom line.</p>
                            <p>Our Pay to Cash Service helps you to gain an edge, you need to accelerate and automate procurement processes, proactively ensure compliance, and find new ways to cut the costs. The key capabilities help to streamline the sourcing and contract management on a platform that can keep up with the change and adapt to your needs.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Transparent Supplier Relationship</li>
                                            <li>User Adoption</li>
                                            <li>Contract Compliance</li>
                                            <li>Invoice Management</li>
                                            <li>Accounts Payable Management</li>
                                            <li>Vendor Payment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p> Suppliers want transparent relationships with their customers. The more they know, the better. The best procure-to-pay solutions eliminate barriers to entry, enhance visibility into the partnership between suppliers and buyers, and reduce the costs of conducting business.
                            The automation of workflows in next-generation P2P solutions minimizes time spent on manual processes, and can even make existing automated processes more effective. Since this is a comparatively lengthy process, with third-party suppliers in between, stringent checks are required for each step to avoid any miscalculations. When the system is handling most of the checks, the chances of error are significantly decreased.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Purchasing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Vendor Management
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Inventory Management
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Warehouse Management
                                    </div>
                                </div>
            
                               
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>SAP Material Management (MM)</span></li>
                                <li><span>SAP Financial Accounting (FI)</span></li>
                               
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent